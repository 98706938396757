import { useEffect, useState } from "react";

import axios from "axios";

import Loader from "../assets/Loader";
import Header from "../assets/Header";
import Footer from "../assets/Footer";

function App() {
  const [Loading, setLoading] = useState(true);
  const [Data, setData] = useState({});
  
  useEffect(() => {
    const run = async () => {
      let discord;

      try {
        discord = await axios.get("https://canary.discord.com/api/invite/sdGWm8JCfF?with_counts=true");
      } catch {
        discord = {
          data: {
            approximate_member_count: "N/A"
          }
        };
      }

      setData({
        members: discord.data.approximate_member_count
      });
      
      setLoading(false);
    };

    run();
  }, [setData]);

  return (<>
    <Loader className={Loading ? "" : "hidden"} />
    <div className={(Loading ? "hidden" : "")}>
      <Header />
      <div className="header-color">
        <div className="max-w-7xl mx-auto px-8">
          <h1 className="text-4xl md:text-7xl font-extrabold" style={{maxWidth: "9em", paddingTop: ".75em"}}>A Discord server for developers.</h1>
          <h2 className="text-xl font-semibold mt-4" style={{ maxWidth: "20em" }}>Join {Data.members} developers, and have fun!</h2>
        </div>
      </div>
      <div className="max-w-7xl mx-auto px-8 mt-4 mb-8">
        <h1 className="text-3xl font-bold">Why should you join ariabyte?</h1>
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-4 my-4">

          <div className="B p-4 rounded-lg shadow-lg">
            <p className="absolute text-xs">Soon</p>
            <div className="w-16 h-16 bg-white mx-auto rounded-full p-4">
              <svg className="mx-auto" xmlns="http://www.w3.org/2000/svg" fill="#ff004e" width="30" height="30" viewBox="0 0 24 24"><path d="M17 22v2h-10v-2h10zm0-4h-10v2h10v-2zm-10-7v5h10v-5h6l-11-11-11 11h6z" /></svg>
            </div>
            <h1 className="text-xl font-semibold text-center">Level up!</h1>
            <p className="font-thin text-center mt-1">Chat, level up, and get access to special channels and cool perks!</p>
          </div>

          <div className="B p-4 rounded-lg shadow-lg">
            <div className="w-16 h-16 bg-white mx-auto rounded-full p-4">
              <svg className="mx-auto" xmlns="http://www.w3.org/2000/svg" fill="#ff004e" width="30" height="30" viewBox="0 0 24 24"><path d="M0 0v19h24v-19h-24zm22 17h-20v-15h20v15zm-6.599 4l2.599 3h-12l2.599-3h6.802zm-6.401-16l6 4.674-2.538.427 1.538 3.095-1.571.804-1.546-3.157-1.883 1.759v-7.602z" /></svg>
            </div>
            <h1 className="text-xl font-semibold text-center">Share your projects!</h1>
            <p className="font-thin text-center mt-1">Creating something new?<br/>Share it with us!</p>
          </div>

          <div className="B p-4 rounded-lg shadow-lg">
            <div className="w-16 h-16 bg-white mx-auto rounded-full p-4">
              <svg className="mx-auto" xmlns="http://www.w3.org/2000/svg" fill="#ff004e" width="30" height="30" viewBox="0 0 24 24"><path d="M3.848 19h-.848c-.796 0-1.559-.316-2.121-.879-.563-.562-.879-1.325-.879-2.121v-3c0-7.175 5.377-13 12-13s12 5.825 12 13v3c0 .796-.316 1.559-.879 2.121-.562.563-1.325.879-2.121.879h-.848c-2.69 4.633-6.904 5-8.152 5-1.248 0-5.462-.367-8.152-5zm16.152-5.876c-.601.236-1.269-.18-1.269-.797 0-.304-.022-.61-.053-.915-1.761-.254-3.618-1.926-3.699-3.723-1.315 2.005-4.525 4.17-7.044 4.17 1.086-.699 1.839-2.773 1.903-3.508-.581 1.092-2.898 3.136-4.551 3.487l-.018.489c0 .619-.669 1.032-1.269.797v3.771c.287.256.632.464 1.041.594.225.072.412.224.521.424 2.206 4.046 5.426 4.087 6.438 4.087.929 0 3.719-.035 5.877-3.169-1.071.433-2.265.604-3.759.653-.37.6-1.18 1.016-2.118 1.016-1.288 0-2.333-.784-2.333-1.75s1.045-1.75 2.333-1.75c.933 0 1.738.411 2.112 1.005 1.9-.026 4.336-.334 5.888-2.645v-2.236zm-11-.624c.686 0 1.243.672 1.243 1.5s-.557 1.5-1.243 1.5-1.243-.672-1.243-1.5.557-1.5 1.243-1.5zm6 0c.686 0 1.243.672 1.243 1.5s-.557 1.5-1.243 1.5-1.243-.672-1.243-1.5.557-1.5 1.243-1.5zm5.478-1.5h1.357c-.856-5.118-4.937-9-9.835-9-4.898 0-8.979 3.882-9.835 9h1.357c.52-4.023 3.411-7.722 8.478-7.722s7.958 3.699 8.478 7.722z" /></svg>
            </div>
            <h1 className="text-xl font-semibold text-center">Fast support!</h1>
            <p className="font-thin text-center mt-1">Feel free to open a ticket to ask for help for our projects!</p>
          </div>

          <div className="B p-4 rounded-lg shadow-lg">
            <p className="absolute text-xs">Soon</p>
            <div className="w-16 h-16 bg-white mx-auto rounded-full p-4">
              <svg className="mx-auto" xmlns="http://www.w3.org/2000/svg" fill="#ff004e" width="30" height="30" viewBox="0 0 24 24"><path d="M24 10.935v2.131l-8 3.947v-2.23l5.64-2.783-5.64-2.79v-2.223l8 3.948zm-16 3.848l-5.64-2.783 5.64-2.79v-2.223l-8 3.948v2.131l8 3.947v-2.23zm7.047-10.783h-2.078l-4.011 16h2.073l4.016-16z" /></svg>            </div>
            <h1 className="text-xl font-semibold text-center">Hire programmers!</h1>
            <p className="font-thin text-center mt-1">Need a website or do you need a freelance job? We got you covered!</p>
          </div>

        </div>

        <h1 className="text-3xl font-bold">Our projects</h1>
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-4 my-4">

          <a target="_blank" rel="noreferrer" href="https://noice.link" className="B p-4 rounded-lg shadow-lg">
            <div className="w-16 h-16 bg-white mx-auto rounded-full p-4">
              <svg className="mx-auto" xmlns="http://www.w3.org/2000/svg" fill="#ff004e" width="30" height="30" viewBox="0 0 24 24"><path d="M6.188 8.719c.439-.439.926-.801 1.444-1.087 2.887-1.591 6.589-.745 8.445 2.069l-2.246 2.245c-.644-1.469-2.243-2.305-3.834-1.949-.599.134-1.168.433-1.633.898l-4.304 4.306c-1.307 1.307-1.307 3.433 0 4.74 1.307 1.307 3.433 1.307 4.74 0l1.327-1.327c1.207.479 2.501.67 3.779.575l-2.929 2.929c-2.511 2.511-6.582 2.511-9.093 0s-2.511-6.582 0-9.093l4.304-4.306zm6.836-6.836l-2.929 2.929c1.277-.096 2.572.096 3.779.574l1.326-1.326c1.307-1.307 3.433-1.307 4.74 0 1.307 1.307 1.307 3.433 0 4.74l-4.305 4.305c-1.311 1.311-3.44 1.3-4.74 0-.303-.303-.564-.68-.727-1.051l-2.246 2.245c.236.358.481.667.796.982.812.812 1.846 1.417 3.036 1.704 1.542.371 3.194.166 4.613-.617.518-.286 1.005-.648 1.444-1.087l4.304-4.305c2.512-2.511 2.512-6.582.001-9.093-2.511-2.51-6.581-2.51-9.092 0z" /></svg>
            </div>
            <h1 className="text-xl font-semibold text-center">noice.link</h1>
            <p className="font-thin text-center mt-1">A cool, customizable, fun and easy URL shortener.</p>
          </a>

          <a target="_blank" rel="noreferrer" href="https://caards.me" className="B p-4 rounded-lg shadow-lg">
            <div className="w-16 h-16 bg-white mx-auto rounded-full p-4">
              <svg className="mx-auto" xmlns="http://www.w3.org/2000/svg" fill="#ff004e" width="30" height="30" viewBox="0 0 24 24"><path d="M20.822 18.096c-3.439-.794-6.64-1.49-5.09-4.418 4.72-8.912 1.251-13.678-3.732-13.678-5.082 0-8.464 4.949-3.732 13.678 1.597 2.945-1.725 3.641-5.09 4.418-3.073.71-3.188 2.236-3.178 4.904l.004 1h23.99l.004-.969c.012-2.688-.092-4.222-3.176-4.935z" /></svg>
            </div>
            <h1 className="text-xl font-semibold text-center">Caards</h1>
            <p className="font-thin text-center mt-1">A super customizable Caard to share so people can get to know you.</p>
          </a>

          <a target="_blank" rel="noreferrer" href="https://economy.party" className="B p-4 rounded-lg shadow-lg">
            <p className="absolute text-xs">Soon</p>
            <div className="w-16 h-16 bg-white mx-auto rounded-full p-4">
              <svg className="mx-auto" xmlns="http://www.w3.org/2000/svg" fill="#ff004e" width="30" height="30" viewBox="0 0 24 24"><path d="M18 10c-3.313 0-6 2.687-6 6s2.687 6 6 6 6-2.687 6-6-2.687-6-6-6zm.5 8.474v.526h-.5v-.499c-.518-.009-1.053-.132-1.5-.363l.228-.822c.478.186 1.114.383 1.612.27.574-.13.692-.721.057-1.005-.465-.217-1.889-.402-1.889-1.622 0-.681.52-1.292 1.492-1.425v-.534h.5v.509c.362.01.768.073 1.221.21l-.181.824c-.384-.135-.808-.257-1.222-.232-.744.043-.81.688-.29.958.856.402 1.972.7 1.972 1.773.001.858-.672 1.315-1.5 1.432zm-7.911-5.474h-2.589v-2h3.765c-.484.602-.881 1.274-1.176 2zm-.589 3h-2v-2h2.264c-.166.641-.264 1.309-.264 2zm2.727-6h-4.727v-2h7v.589c-.839.341-1.604.822-2.273 1.411zm2.273-6h-7v-2h7v2zm0 3h-7v-2h7v2zm-4.411 12h-2.589v-2h2.069c.088.698.264 1.369.52 2zm-10.589-11h7v2h-7v-2zm0 3h7v2h-7v-2zm12.727 11h-4.727v-2h3.082c.438.753.994 1.428 1.645 2zm-12.727-5h7v2h-7v-2zm0 3h7v2h-7v-2zm0-6h7v2h-7v-2z" /></svg>            </div>
            <h1 className="text-xl font-semibold text-center">economy.party</h1>
            <p className="font-thin text-center mt-1">A fun economy Discord bot!<br/>Buy, sell and earn!</p>
          </a>

          <a target="_blank" rel="noreferrer" href="https://memes.business" className="B p-4 rounded-lg shadow-lg">
            <p className="absolute text-xs">Soon</p>
            <div className="w-16 h-16 bg-white mx-auto rounded-full p-4">
              <svg className="mx-auto" xmlns="http://www.w3.org/2000/svg" fill="#ff004e" width="30" height="30" viewBox="0 0 24 24"><path d="M24 10.935v2.131l-8 3.947v-2.23l5.64-2.783-5.64-2.79v-2.223l8 3.948zm-16 3.848l-5.64-2.783 5.64-2.79v-2.223l-8 3.948v2.131l8 3.947v-2.23zm7.047-10.783h-2.078l-4.011 16h2.073l4.016-16z" /></svg>            </div>
            <h1 className="text-xl font-semibold text-center">memes.business</h1>
            <p className="font-thin text-center mt-1">Memes! Memes! Memes!</p>
          </a>

        </div>

      </div>
    </div>
    
    <Footer />
  </>);
}

export default App;