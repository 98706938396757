import logo from "./logo.png";

function App(props) {
    return (<>
        <footer className="w-full p-8">
            <div className="max-w-7xl mx-auto px-8">
                <img src={logo} alt="Ariabyte Logo" className="h-8" />
                
            </div>
    </footer>
    </>);
}

export default App;