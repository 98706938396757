function App(props) {
    return (<>
        <header className="w-full p-8">
            <div className="max-w-7xl mx-auto px-8 flex">
                <div className="my-auto">
                    <svg viewBox="0 0 45 45" height="35" width="35" version="1.1">
                        <path
                            style={{
                                "fontStyle": "normal",
                                "fontWeight": 700,
                                "fontSize": "72px",
                                "lineHeight": 1,
                                "fontFamily": "Brandmark1 Bold",
                                "fontVariantLigatures": "normal",
                                "textAlign": "center",
                                "textAnchor": "middle"
                            }}
                            d="m 405.34325,-38.016 0.072,2.304 c -2.88,-1.44 -6.048,-2.232 -9.288,-2.232 -2.736,0 -5.472,0.504 -7.992,1.584 -2.448,1.08 -4.608,2.52 -6.552,4.392 -1.872,1.872 -3.312,4.104 -4.392,6.552 -1.008,2.52 -1.584,5.256 -1.584,7.992 0,2.736 0.576,5.472 1.584,7.992 1.08,2.448 2.52,4.608 4.392,6.552 1.944,1.872 4.104,3.312 6.552,4.392 2.52,1.008 5.256,1.584 7.992,1.584 3.24,0 6.408,-0.792 9.288,-2.232 l -0.072,1.656 h 10.8 v -40.536 z m -4.32,28.944 c -1.44,0.864 -3.168,1.368 -4.896,1.368 -5.328,0 -9.72,-4.392 -9.72,-9.72 0,-5.328 4.392,-9.72 9.72,-9.72 1.872,0 3.744,0.504 5.256,1.584 1.584,0.936 2.808,2.376 3.528,4.032 0.648,1.296 0.936,2.664 0.936,4.104 0,1.728 -0.432,3.384 -1.296,4.896 -0.864,1.44 -2.016,2.664 -3.528,3.456 z"
                            strokeWidth="0"
                            strokeLinejoin="miter"
                            strokeMiterlimit="2"
                            fill="#ffffff"
                            stroke="#ffffff"
                            transform="translate(-375 40)"
                        >
                        </path>
                    </svg>
                </div>

                <div className="ml-auto my-auto">
                    <form action="https://discord.gg/sdGWm8JCfF">
                        <button className="py-2 px-8 font-semibold rounded-lg focus:ring flex">
                            Join
                        </button>
                    </form>
                </div>
            </div>
        </header>
    </>);
}

export default App;